import React, { useEffect, useState } from "react";
import "./homestyle.css";
import Accordion from "react-bootstrap/Accordion";
import Cards from "../../Component/Card/Card";
import { useNavigate } from "react-router-dom";
import { GetData } from "../../Common/api/Api";
import ModalAdd from "../../Component/Modal/modal";
import { Spinner } from "react-bootstrap"
import { Select, Space } from "antd";
import { removeEditLocationData } from "../../redux/locationSlice";
import { useDispatch, useSelector } from "react-redux";
import { FaAngleUp } from "react-icons/fa6";
import { setSelectedMarker } from "../../redux/markerSlice";
import PaginationComponent from "../../Component/PaginationComponent";
import { GoogleMap, InfoWindowF, MarkerF } from '@react-google-maps/api';


const { Option } = Select;
const Home = () => {
  const [tags, setTags] = useState([]);
  const navigate = useNavigate();
  const [address, setAddress] = useState("");
  const [remarks, setRemarks] = useState("");
  const propertyid = useSelector((state) => state?.marker?.selectedMarker)
  const [checkedTags, setCheckedTags] = useState([]);
  const [stateChecked, setstateChecked] = useState([]);
  const [stuatusChecked, setStuatusChecked] = useState([]);
  const [state, setState] = useState([]);
  const [property, setProperty] = useState([]);
  const [residents, setResidents] = useState([]);
  const [areaMaster, setAreaMaster] = useState([]);
  const [areaChecked, setAreaChecked] = useState([]);
  const [mapData, setMapData] = useState([]);
  const [residentId, setResidentId] = useState("");
  const [loader, setLoader] = useState(false);
  const [stuatusMaster, setStuatusMaster] = useState([]);
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalPages, setTotalPages] = useState(1);
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      FatchDataAlldata();
    }, 1000);
  }, [
    address,
    remarks,
    checkedTags,
    stateChecked,
    residentId,
    areaChecked,
    stuatusChecked,
    page,
    pageSize,
  ]);
  console.warn = function() {};
  useEffect(() => {
    FatchData();
    FatchDataState();
    FatchDataresidents();
    FatchDataAreaMster();
    FatchDatastaust();
  }, []);

  const handleCheckboxChange = (tag_id) => {
    const updatedTags = checkedTags.includes(tag_id)
      ? checkedTags.filter((id) => id !== tag_id)
      : [...checkedTags, tag_id];
    setCheckedTags(updatedTags);
  };

  const handleCheckboxChangearea = (area_id) => {
    const updatedAreas = areaChecked.includes(area_id)
      ? areaChecked.filter((id) => id !== area_id)
      : [...areaChecked, area_id];
    setAreaChecked(updatedAreas);
  };

  const handleCheckboxChangestate = (state_id) => {
    const updatedTags = stateChecked.includes(state_id)
      ? stateChecked.filter((id) => id !== state_id)
      : [...stateChecked, state_id];
    setstateChecked(updatedTags);
  };

  const handleCheckboxChangestuatus = (stuatus_id) => {
    const updatedTags = stuatusChecked.includes(stuatus_id)
      ? stuatusChecked.filter((id) => id !== stuatus_id)
      : [...stuatusChecked, stuatus_id];
    setStuatusChecked(updatedTags);
  };

  const FatchDataAreaMster = async () => {
    try {
      const res = await GetData("/area/all");
      setAreaMaster(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const FatchDataAlldata = async () => {
    setLoader(true);
    try {
      const res = await GetData(
        `/property?address=${address}&property_tag_id=${checkedTags}&property_association_id=${residentId}&remarks=${remarks}&state_id=${stateChecked}&area_id=${areaChecked}&status_id=${stuatusChecked}&page=1&pageSize=99999`
      );
      setTotalPages(Math.ceil(res.count / pageSize));
      setProperty(res.data);
      setMapData(res.data);
      if (
        address ||
        remarks ||
        checkedTags.length > 0 ||
        stateChecked.length > 0 ||
        residentId.length > 0 ||
        areaChecked.length > 0
      ) {
        dispatch(
          setSelectedMarker({ selectedMarker: res.data[0], isActtion: false })
        );
      }
      setLoader(false);
    } catch (error) {
      console.log(error);
    }
  };
  const FatchDataState = async () => {
    try {
      const res = await GetData("/state/all");
      setState(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const FatchData = async () => {
    try {
      const res = await GetData("/tags/all");
      setTags(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const FatchDatastaust = async () => {
    try {
      const res = await GetData("/status/all");
      setStuatusMaster(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const LoggoutFunction = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");
    navigate("/login");
  };
  const [modalShow, setModalShow] = useState(false);


  const isAdmin = localStorage.getItem("isAdmin");

  const FatchDataresidents = async () => {
    try {
      const res = await GetData("/residents_association/all");
      setResidents(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (value) => {
    setResidentId(value);
  };
  const [name, setName] = useState(true);
  const handeNameChange = () => {
    setName(!name);
  };
  const handlePaginationClick = (pageNum) => {
    setPage(pageNum);
    // You might want to fetch data for the new page here
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    // You might want to re-fetch data with the new page size here
  };

  const pageSizeOptions = [10, 20, 30, 50, 100];




  const [activeMarker, setActiveMarker] = useState(propertyid);

  const handleActiveMarker = (marker) => {

    if (marker?.property_id === activeMarker?.property_id) {
      return;
    }
    dispatch(setSelectedMarker({ selectedMarker: marker, isActtion: true }))
    setActiveMarker(marker);
  };

  useEffect(() => {
    setActiveMarker(propertyid);
  }, [propertyid]);

  return (
    <div className="main h-100">
      {/* ヘッダ */}
      <div className="main-header p-3">
        <h3>
          {isAdmin === "true"
            ? "新潟県三条市空き家マップ"
            : "新潟県三条市空き家マップ"}
        </h3>
        <button className="btn btnLoggout" onClick={() => LoggoutFunction()}>
          <img src="/assets/images/logout.png" alt="img" className="img-fluid me-2" />
          ログアウト
        </button>
      </div>
      <div className="main-contents container-fluid px-3 pb-3">
        {/* 検索条件（アコーディオン） */}
        <div className="search-box">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
              <Accordion.Header onClick={handeNameChange}>
                <FaAngleUp />
                <span>
                  {name ? "検索条件を展開する" : "検索条件を折り畳む"}
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <div className="collapse-box">
                  <form>
                    {isAdmin === "true" && (
                      <>
                        <div className="mb-3">
                          <label className="form-label d-block">エリア</label>

                          {areaMaster.map((item) => (
                            <div
                              key={item.area_id}
                              className="form-check form-check-inline"
                            >
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id={`${item.area_id}inlineCheckboxarea`}
                                value={item.area_name}
                                checked={areaChecked.includes(item.area_id)}
                                onChange={() =>
                                  handleCheckboxChangearea(item.area_id)
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`${item.area_id}inlineCheckboxarea`}
                              >
                                {item.area_name}
                              </label>
                            </div>
                          ))}
                        </div>
                        <p>自治会</p>

                        <Select
                          mode="multiple"
                          style={{
                            width: "100%",
                          }}
                          placeholder=""
                          
                          onChange={handleChange}
                          optionLabelProp="label"
                          className="mb-5 "
                        >
                          {residents.map((item, index) => (
                            <Option
                              key={index}
                              value={item?.residents_association_id}
                              label={item?.residents_association_name}
                            >
                              <Space>{item?.residents_association_name}</Space>
                            </Option>
                          ))}
                        </Select>
                      </>
                    )}
                    <div className="mb-3">
                      <label htmlFor="address" className="form-label">
                        住所
                      </label>
                      <input
                        type="text"
                        value={address}
                        className="form-control"
                        id="address"
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label d-block">状態</label>

                      {state.map((item) => (
                        <div
                          key={item.state_id}
                          className="form-check form-check-inline"
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={`${item.state_id}inlineCheckbox`}
                            value={item.state_name}
                            checked={stateChecked.includes(item.state_id)}
                            onChange={() =>
                              handleCheckboxChangestate(item.state_id)
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`${item.state_id}inlineCheckbox`}
                          >
                            {item.state_name}
                          </label>
                        </div>
                      ))}
                    </div>
                    <div className="mb-3">
                      <label className="form-label d-block">タグ</label>
                      {tags.map((item) => (
                        <div
                          key={item.tag_id}
                          className="form-check form-check-inline"
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={`${item.tag_id}inlineCheckboxTag`}
                            value={item.tag_name}
                            checked={checkedTags.includes(item.tag_id)}
                            onChange={() => handleCheckboxChange(item.tag_id)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`${item.tag_id}inlineCheckboxTag`}
                          >
                            {item.tag_name}
                          </label>
                        </div>
                      ))}
                    </div>
                    {isAdmin === "true" && (
                      <div className="mb-3">
                        <label className="form-label d-block">
                          物件のステータス
                        </label>
                        {stuatusMaster.map((item) => (
                          <div
                            key={item.status_id}
                            className="form-check form-check-inline"
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={`${item.status_id}inlineCheckboxStatus`}
                              value={item.status_name}
                              checked={stuatusChecked.includes(item.status_id)}
                              onChange={() =>
                                handleCheckboxChangestuatus(item.status_id)
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`${item.status_id}inlineCheckboxStatus`}
                            >
                              {item.status_name}
                            </label>
                          </div>
                        ))}
                      </div>
                    )}
                    <div className="mb-3">
                      <label htmlFor="remarks" className="form-label">
                        備考
                      </label>
                      <input
                        type="text"
                        value={remarks}
                        className="form-control"
                        id="remarks"
                        onChange={(e) => setRemarks(e.target.value)}
                      />
                    </div>
                  </form>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        {/* メイン */}
        <div className="main-contents-container row">
          {/* マップ */}
          <div className="col-md-6 h-100">
            <div className="position-relative h-100">

              {loader ? (
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" />
                </div>
              ) :

                <GoogleMap
                  center={{ lat: 37.636681, lng: 138.961652 }}
                  zoom={13}
                  mapContainerStyle={{ width: "100%", height: "100%", "border-radius":"10px" }}
                  onClick={() => setActiveMarker(null)}
                >
                  {mapData.map((item) => (
                    <MarkerF
                      key={item?.property_id}
                      position={{ lat: Number(item.latitude), lng: Number(item.longitude) }}
                      onClick={() => handleActiveMarker(item)}
                      icon={{
                        url: activeMarker?.property_id === item.property_id ? "assets/images/Checkpoint1.png" : "assets/images/Checkpoint.png",
                        scaledSize: activeMarker?.property_id === item.property_id ? new window.google.maps.Size(50, 50) : new window.google.maps.Size(35, 35)
                      }}
                    >
                      {activeMarker?.property_id === item?.property_id && (
                        <InfoWindowF position={{ lat: Number(item.latitude), lng: Number(item.longitude) }} onClose={() => setActiveMarker(null)}>
                          <div>
                            <p>{item.address}</p>
                          </div>
                        </InfoWindowF>
                      )}
                    </MarkerF>
                  ))}

                </GoogleMap>
               
              }
              <button
                type="button"
                className="btn btn-primary add-btn"
                onClick={() => {
                  setModalShow(true);
                  dispatch(removeEditLocationData());
                }}
              >
                <img
                  src="/assets/images/plus-icon.png"
                  className="img-fluid"
                  alt=""
                />
                物件を追加
              </button>
              <ModalAdd show={modalShow} onHide={() => setModalShow(false)} />
            </div>
          </div>

          <div className="col-md-6 h-100 ps-0">
            <div className="cardBox cardsrcollbar">
              <Cards item={property} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
