import React, { useEffect } from 'react'
import './NotFound.css'


export default function NotFound() {
  useEffect(() => {
    document.title = '404 Not Found';
  }, []);
  return (
    <> 
    <center className="notFoundContainer">
      <h1 className='header'>404 Not Found</h1>
    </center>
    <hr />
    <center className='fontFmaily'>nginx</center>
    </>
  )
}
