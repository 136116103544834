export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const ONLY_STRING_REGEX = /^[a-zA-Z]+$/;
export const STRING_WITH_SPACE_REGEX = /^[a-zA-Z\s]+$/;
export const MOBILE_REGEX = /^[0-9]{10}$/;
export const ONLY_DIGIT_REGEX = /^[0-9]+$/;
export const IFSC_CODE_REGEX = /^[A-Z]{4}0[A-Z0-9]{6}$/;
export const PAN_NO_REGEX = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
export const GRADE_REGEX = /^(100(\.0{1,2})?|\d{1,2}(\.\d{1,2})?)$/;
export const ALPHA_NUMERIC_WITH_SPACE_REGEX = /^[a-zA-Z0-9\s]+$/;
export const TEMPLATE_ID_REGEX = /^[a-zA-Z_]+$/; // used in email template
