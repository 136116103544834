
import axios from "axios";
import {toast} from "react-toastify";
import { BAD_REQUEST, CODE_SUCCESS, INTERNAL_SERVER, TOKEN_ERROR, baseURL } from "./common.js";

const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 20000,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
});
const axiosInstanceFileUpload = axios.create({
  baseURL: baseURL,
  timeout: 20000,
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
});



export const GetData = async (endpoint) => {
  try {
    const response = await axiosInstance.get(endpoint);
    if (response.status === CODE_SUCCESS) {
      return response.data;
    }
  } catch (error) {

    if (error.response.status === INTERNAL_SERVER) {
      toast.error(`${error.response.data.error}`, {position: "bottom-right"});
    }
    if (error.response.status === TOKEN_ERROR) {
      toast.error(`${error.response.data.error}`, {position: "bottom-right"});
      localStorage.removeItem("token");
      localStorage.removeItem("isAdmin")
      window.location.href = "/login";
    }
  }
};

export const PostData = async (endpoint, data) => {
  try {
    const response = await axiosInstance.post(endpoint, data);
    return response.data;
  } catch (error) {
    if (error.response.status === INTERNAL_SERVER) {
      toast.error(`${error.response.data.error}`, {position: "bottom-right"});
    }
    if (error.response.status === TOKEN_ERROR) {
      toast.error(`${error.response.data.error}`, {position: "bottom-right"});
      localStorage.removeItem("token");
      localStorage.removeItem("isAdmin")
      window.location.href = "/login";
    }
  }
};
export const PostDataUpload = async (endpoint, data) => {
  try {
    const response = await axiosInstanceFileUpload.post(endpoint, data);
    return response.data;
  } catch (error) {
    if (error.response.status === INTERNAL_SERVER) {
      toast.error(`${error.response.data.error}`, {position: "bottom-right"});      
    }
    if (error.response.status === TOKEN_ERROR) {
      toast.error(`${error.response.data.error}`, {position: "bottom-right"});
      localStorage.removeItem("token");
      localStorage.removeItem("isAdmin")
      window.location.href = "/login";
    }
    if (error.response.status === BAD_REQUEST) {
      toast.error(`${error.response.data.error}`, {position: "bottom-right"});     
    }
  }
};

export const PutDataUpload = async (endpoint, data) => {
  try {
    const response = await axiosInstanceFileUpload.put(endpoint, data);
    return response.data;
  } catch (error) {
    
    if (error.response.status === INTERNAL_SERVER) {
      toast.error(`${error.response.data.error}`, {position: "bottom-right"});
    }
    if (error.response.status === TOKEN_ERROR) {
      toast.error(`${error.response.data.error}`, {position: "bottom-right"});
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    if (error.response.status === BAD_REQUEST) {
      toast.error(`${error.response.data.error}`, {position: "bottom-right"});   
    }
  }
};
export const PutData = async (endpoint, data) => {
  try {
    const response = await axiosInstance.put(endpoint, data);
    return response.data;
  } catch (error) {
    if (error.response.status === INTERNAL_SERVER) {
      toast.error(`${error.response.data.error}`, {position: "bottom-right"});
    }
    if (error.response.status === TOKEN_ERROR) {
      toast.error(`${error.response.data.error}`, {position: "bottom-right"});
      localStorage.removeItem("token");
      localStorage.removeItem("isAdmin")
      window.location.href = "/login";
    }
  }
};

export const DeleteData = async (endpoint) => {
  try {
    const response = await axiosInstance.delete(endpoint);
    return response.data;
  } catch (error) {
    if (error.response.status === INTERNAL_SERVER) {
      toast.error(`${error.response.data.error}`, {position: "bottom-right"});
    }
    if (error.response.status === TOKEN_ERROR) {
      toast.error(`${error.response.data.error}`, {position: "bottom-right"});
      localStorage.removeItem("token");
      localStorage.removeItem("isAdmin")
      window.location.href = "/login";
    }
  }
};

