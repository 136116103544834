import logo from "./logo.svg";
import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import Home from "./Pages/home/Home";
import { BrowserRouter, Routes, Route,  } from "react-router-dom";
import Login from "./Pages/login/login";
import PrivetRoute, { PublicRoute } from "./Component/privetRoute/PrivetRoute";
import  { toastConfig } from 'react-simple-toasts';
import 'react-simple-toasts/dist/theme/dark.css';
import NotFound from "./Pages/NotFound/NotFound";

toastConfig({ theme:'dark',maxVisibleToasts: 1});

function App() {
 
  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PrivetRoute><Home /></PrivetRoute>} />
        <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />  
        <Route path="*" element={<NotFound />}  />
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
