import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./modal.css";
import Slider from "react-slick";

import "react-datepicker/dist/react-datepicker.css";
import {
  ADDRESS_FILED100,
  ADDRESS_FILED255,
  ADDRESS_FILED50,
  ADDRESS_FILED25,
  DIGIT_FIELD_ERROR,
  FIELD_REQUIRED_ERROR,
} from "../../Common/Validation/errorMessage";
import {  useForm } from "react-hook-form";
import {
  DeleteData,
  GetData,
  PostDataUpload,
  PutDataUpload,
} from "../../Common/api/Api";
import { Spinner } from "react-bootstrap";
import { Select,  } from "antd";
import GoogleMapComponent from "../marker/marker";
import { useDispatch, useSelector } from "react-redux";
import { removeLaglat, removeLocation, setEditLocationData, setLaglat } from "../../redux/locationSlice";
import { toast } from "react-toastify";
import { RxCross2 } from "react-icons/rx";
import { ONLY_DIGIT_REGEX } from "../../Common/Validation/regex";

import { IMG_URL, GOOGLE_MAPS_KEY } from "../../Common/api/common";
import {
  setKey,
  setDefaults,
  fromAddress,
} from "react-geocode";

const ModalAdd = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setValue,
  } = useForm();
  const [tags, setTags] = useState([]);
  setDefaults({
    key:GOOGLE_MAPS_KEY,
    language: "es", // Default language for responses.
    region: "es", // Default region for responses.
  });
  setKey(GOOGLE_MAPS_KEY)
  const [next, SetNext] = useState(true);
  const [stateMaster, setStateMaster] = useState([]);
  const [files, setFiles] = useState([]);
  const [residents, setResidents] = useState([]);
  const [slideshow, Setslideshow] = useState(1);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [cancelModalShow, setCancelModalShow] = useState(false);
  const [registerData, setRegisterData] = useState({});
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [buttonloder, setButtonloder] = useState(false);
  const [deleteImge, setDeleteImge] = useState([]);
  const [stuatusMaster, setStuatusMaster] = useState([]);
  const [locationerror, setLocationError] = useState(false);
  const [skipbuttonLode, setSkipbuttonLode] = useState(false)
  const [oldLatlong, setOldLatlong] = useState({})
  const location = useSelector((state) => state?.location);

  const [data, setData] = useState({});
  const [query, setQuery] = useState("");
  /*******LOCATION ********/
  useEffect(() => {
    if (typeof location?.locationData?.geometry?.location?.lat === "function") {
      const newlat1 = location?.locationData?.geometry?.location?.lat();
      const newlng1 = location?.locationData?.geometry?.location?.lng();

      setData({
        lat: newlat1,
        lng: newlng1,
      });
    } else {
      const newlat = location?.locationData?.geometry?.location.lat;
      const newlng = location?.locationData?.geometry?.location.lng;
      setData({
        lat: newlat,
        lng: newlng,
      });
    }
    setQuery(location?.locationData?.formatted_address);
  }, [location.locationData]);

  const adressdat= getValues("address")
  useEffect(() => {
    if (adressdat) {
        fromAddress(adressdat)
            .then(({ results }) => {
                const { lat, lng } = results[0]?.geometry?.location;
                if (lat && lng) {
                    setData({
                        lat: lat,
                        lng: lng,
                    });
                }
            })
            .catch(console.error);
    }
}, [adressdat]);

  /*******FATCH DATA ********/
  useEffect(() => {
    FatchDataselecte();
    FatchData();
    FatchDataresidents();
    FatchDatastaust();
  }, []);

  /*******FATCH DATA EDITE ********/
  const propertyId = props.propertyId;
  useEffect(() => {
    if (propertyId) {
      fetchData();
    }
  }, [props.propertyId]);

  const fetchData = async () => {
    try {
      if (propertyId) {
        const response = await GetData(`/property/${propertyId}`);
        dispatch(setEditLocationData(response.data));
        setValue("owner_contact", response?.data?.owner_contact);
        setValue("owner_remarks", response?.data?.owner_remarks);
        setValue("owner_address", response?.data?.owner_address);
        setValue("owner_name", response?.data?.owner_name);
        setValue("remarks", response?.data?.remarks);
        setSelectedImages(response?.data?.property_images);
        setValue("property_status", response?.data?.property_status);
        setValue("state_id", response?.data?.state_id);
        setValue("residents_association_id",response?.data?.association_detail?.residents_association_id);
        setValue("building_year", response?.data?.building_year);
        setData({
          lat: response.data.latitude,
          lng: response.data.longitude,
        });
        setOldLatlong({
          lat: response.data.latitude,
          lng: response.data.longitude,
        });
        const tags = response?.data?.property_tags?.map(
          (item) => item.tag_detail.tag_id
        );
        setSelectedTags(tags);
        setValue("tag_ids",response?.data?.property_tags?.map((item) => item.tag_detail.tag_id));
        setValue("property_images",response?.data?.property_images.map((e) => e.filePath));
        setValue("address",response?.data?.address);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /************API CALLING START*********/
  const FatchData = async () => {
    try {
      const res = await GetData("/tags/all");
      setTags(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const FatchDataselecte = async () => {
    try {
      const res = await GetData("/state/all");
      setStateMaster(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const FatchDataresidents = async () => {
    try {
      const res = await GetData("/residents_association/all");
      setResidents(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const FatchDatastaust = async () => {
    try {
      const res = await GetData("/status/all");
      setStuatusMaster(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  /************API CALLING END*********/
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slideshow,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1750,
        settings: {
          slidesToShow: slideshow > 1 ? `${slideshow - 1}` : 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: slideshow > 2 ? `${slideshow - 2}` : 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: slideshow > 3 ? `${slideshow - 3}` : 1,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: slideshow > 4 ? `${slideshow - 4}` : 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  /*********** All FUCTION START *********/

  // const handleChange = (value) =>{
  //   setResidentId(value)
  // }
  const handlClickCancel = () => {
    setCancelModalShow(true);
  };
  const handlClick = () => {
    setDeleteModalShow(true);
  };
  const handleFileChange = (e) => {
    const files = e.target.files;
    const imglenght = [...files, ...selectedImages];
    if (imglenght.length > 5) {
      toast.error("画像は 5 つまでしか選択できません。", {
        position: "bottom-right",
      });
      e.target.value = null;
      return false;
    }

    const updatedImages = [];
    const allowedExtensions = /\.(jpg|jpeg|png)$/;

    Promise.all(
      Array.from(files).map((file) => {
        return new Promise((resolve) => {
          if (allowedExtensions.test(file.name.toLowerCase())) {
            const image = new Image();
            image.src = URL.createObjectURL(file);
            image.onload = async() => {
              const minHeight = 480;
              if (image.height >= minHeight) {
                // const images = await resizeFile(file); 
                // const newFiles = dataURIToBlob(images);
                setFiles((files) => [...files, file]);
                resolve(file);
              } else {
                toast.error("Image dimensions must be up to 480 pixels", {
                  position: "bottom-right",
                });
                resolve(null);
              }
            };
          } else {
            toast.error("Only jpg, jpeg, png files are allowed.", {
              position: "bottom-right",
            });
            resolve(null);
          }
        });
      })
    ).then((loadedFiles) => {
      const validFiles = loadedFiles.filter((file) => file !== null);
      const result = validFiles.map((item) => ({
        filePath: URL.createObjectURL(item),
      }));
      setSelectedImages([...result, ...selectedImages]);
    });
  };

  const handleRemoveImage = (index) => {
    const newImages = [...selectedImages];
    const newFiles = [...files];
    newFiles.splice(index, 1);

    const indexImage = newImages.findIndex((item, index1) => index1 === index);
    if (!propertyId) {
      newFiles.splice(index, 1);
      setFiles(newFiles);
    }
    if (indexImage !== -1) {
      const deletedImage = newImages[indexImage];
      newImages.splice(indexImage, 1);
      setSelectedImages(newImages);
      setDeleteImge((prevDeleteImge) => [
        ...prevDeleteImge,
        deletedImage.property_image_id,
      ]);
    }
  };

  const checkBoxfunction = (id) => {
    if (selectedTags.includes(id)) {
      setSelectedTags(selectedTags.filter((tagId) => tagId !== id));
    } else {
      setSelectedTags([...selectedTags, id]);
    }
  };

  const clearSteat= () => {
    setFiles([])
    setSelectedTags([])
    setSelectedImages([])
  }
  /*********** All FUCTION END *********/

  const onSubmit = async(values) => {
    dispatch(setLaglat(data))
    SetNext(false);
    setRegisterData(values);
  };
  /******ALL SUBMITDATA START**********/
  const dispatch = useDispatch();

  const allSubmitData = async () => {
    if(!(data.lat && data.lng)){
      setLocationError(true)
      return false
    }
    setLocationError(false)
    var filesData = new FormData();
    filesData.append("state_id", registerData.state_id);
    { registerData.tag_ids &&
        filesData.append("tag_ids", registerData.tag_ids ?? "")}
    filesData.append("remarks", registerData.remarks);
    filesData.append("owner_name", registerData.owner_name);
    filesData.append("owner_contact", registerData.owner_contact ?? "");
    filesData.append("owner_address", registerData.owner_address ?? "");
    filesData.append("owner_remarks", registerData.owner_remarks ?? "");
    { registerData.property_status &&  filesData.append("property_status", registerData.property_status??"");}
    filesData.append("building_year", registerData.building_year);
    for (const file of files) {
      filesData.append("property_images", file);
    }
      filesData.append("longitude", data.lng);
      filesData.append("latitude", data.lat);
    
filesData.append("residents_association",registerData.residents_association_id )
    if (Object.keys(location?.locationData)?.length == 0) {
      filesData.append("address", registerData.address);
    } else {
      filesData.append("address", registerData.address);
    }
    filesData.append("deleted_images", deleteImge);
    setButtonloder(true);
    if (props.propertyId) {
      try {
        let res = await PutDataUpload(
          `/property/${props.propertyId}`,
          filesData
        );
        if (res.status == 200) {
          props.onHide();
          reset();
          SetNext(true);
          toast.success("正常に更新されました");
          setButtonloder(false);
          dispatch(removeLocation());
          dispatch(removeLaglat());
          clearSteat()
          window.location.href = "/";
        }
      } catch (error) {
        setButtonloder(false);
      }
    } else {
      try {
        let res = await PostDataUpload("/property", filesData);
        if (res.status == 200) {
          props.onHide();
          reset();
          toast.success("物件を登録しました");
          SetNext(true);
          setButtonloder(false);
          dispatch(removeLocation());
          dispatch(removeLaglat());
          clearSteat()
          window.location.href = "/";
        }
      } catch (error) {
        console.log(error);
        setButtonloder(false);
      }
    }
  };

  const allSubmitDataSkip = async () => {
    setSkipbuttonLode(true)
    var filesData = new FormData();
    filesData.append("state_id", registerData.state_id);
    {
      registerData.tag_ids &&
        filesData.append("tag_ids", registerData.tag_ids ?? "");
    }
    filesData.append("remarks", registerData.remarks);
    filesData.append("owner_name", registerData.owner_name);
    filesData.append("owner_contact", registerData.owner_contact ?? "");
    filesData.append("owner_address", registerData.owner_address ?? "");
    filesData.append("owner_remarks", registerData.owner_remarks ?? "");
    { registerData.property_status && filesData.append("property_status", registerData.property_status ?? ""); }
    filesData.append("building_year", registerData.building_year);
    for (const file of files) {
      filesData.append("property_images", file);
    }
    
    // filesData.append("longitude", null);
    // filesData.append("latitude",null);

    // frist time property add thay tyre je lat and lng sava thay hoy te edit time te pass kar va na hoy to  bottom 2 line uncommit kari devi  and upar ni 2 line  commit kari nakh vi 
  
    filesData.append("longitude", oldLatlong.lng );
    filesData.append("latitude", oldLatlong.lat);
 

    filesData.append("residents_association", registerData.residents_association_id)
    if (Object.keys(location?.locationData)?.length == 0) {
      filesData.append("address", registerData.address);
    } else {
      filesData.append("address", registerData.address);
    }
    filesData.append("deleted_images", deleteImge);
  
    if (props.propertyId) {
      try {
        let res = await PutDataUpload(
          `/property/${props.propertyId}`,
          filesData
        );
        if (res.status == 200) {
          props.onHide();
          reset();
          SetNext(true);
          toast.success("正常に更新されました");
          setSkipbuttonLode(false)
          dispatch(removeLocation());
          dispatch(removeLaglat());
          clearSteat()
          window.location.href = "/";
        }
      } catch (error) {
        setSkipbuttonLode(false)
      }
    } 
  };
  /******ALL SUBMITDATA END**********/

  /***********DELETE START************/
  const deleteData = async () => {
    setButtonloder(true);
    try {
      const res = await DeleteData(`/property/${props.propertyId}`);
      if (res.status == 200) {
        setButtonloder(false);
        setDeleteModalShow(false);
        props.onHide();
        clearSteat()
        window.location.href = "/";
        toast.success("正常に削除されました");
        props.FatchDataAlldata();
      }
    } catch (error) {
      setButtonloder(false);
      console.log(error);
    }
  };


  /***********DELETE START END************/
  const isAdmin = localStorage.getItem("isAdmin");
  const { Option } = Select;
  return (
    <>
      {props.show && !deleteModalShow && !cancelModalShow && (
        <Modal
          show={props.show}
          onHide={() => {
            props.onHide();
            SetNext(true);
            // reset();
          }}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="ModalBox"
        >
          <Modal.Header>
            <Modal.Title
              id="contained-modal-title-vcenter "
              className="modal-header p-3"
            >
              {props.propertyId ? "プロパティの編集" : "プロパティの追加"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="popup-body">
            {next ? (
              <main>
                <p className="fontWidth">登録する情報を入力してください。</p>
                {props.propertyId && (
                  <div className="d-flex justify-content-end buttn">
                    <button
                      type="button"
                      className="btn btn-outline-danger px-5"
                      onClick={() => handlClick()}
                    >
                      削除
                    </button>
                  </div>
                )}
                <form>
                  <section className="propertyInfo">
                    <h4 className="fontWidth mb-3">物件情報</h4>
                    <div className="ps-3">
                      {isAdmin === "true" && (
                        <>
                          <div className="mb-5">

                            <div className="mb-3 sistuation-input">
                              <label className="form-label">自治会</label>
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                {...register("residents_association_id", {
                                  required: FIELD_REQUIRED_ERROR("自治会の選択は必須です"),
                                })}
                              >
                                <option value="" selected>
                                  選んでください
                                </option>
                                {residents.map((item, index) => (
                                  <option key={index} value={item.residents_association_id}>
                                    {item.residents_association_name}
                                  </option>
                                ))}
                              </select>
                              {errors?.residents_association_id ? (
                                <span className="error">
                                  {errors?.residents_association_id?.message}
                                </span>
                              ) : null}
                            </div>


                            {errors?.residentsassociation && (
                              <span className="error ">
                                {errors.residentsassociation.message}
                              </span>
                            )}
                          </div>
                        </>
                      )}

                      <div className="mb-3 search-location-input">
                        <label className="form-label">住所</label>
                        <input
                          type="text"
                          className="form-control"
                          maxLength="256"
                          {...register("address", {
                            required: FIELD_REQUIRED_ERROR("住所は必須です"),
                            maxLength: {
                              value: 255,
                              message: ADDRESS_FILED255,
                            },
                          })}
                        />
                        {errors?.address? (
                          <span className="error">
                            {errors?.address?.message}
                          </span>
                        ) : null}
                      </div>

                      <div className="mb-3 year">
                        <label className="form-label">
                          <span className="me-4">築年</span>{" "}
                          <a
                            href="https://jsite.mhlw.go.jp/fukushima-roudoukyoku/content/contents/000622407.pdf"
                            target="_blank"
                          >
                            和暦⇔西暦換算表を確認
                          </a>
                        </label>
                        <div className="d-flex align-items-center">
                          <div className="d-flex align-items-center">
                            <input
                              type="text"
                              className="form-control me-1"
                              {...register("building_year", {
                                maxLength: {
                                  value: 25,
                                  message: ADDRESS_FILED25,
                                },
                              })}

                            />
                          </div>
                        </div>
                        {errors?.building_year && (
                          <span className="error">
                            {errors?.building_year?.message}
                          </span>
                        )}
                      </div>
                      <div className="mb-3 sistuation-input">
                        <label className="form-label">状態</label>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          {...register("state_id", {
                            required: FIELD_REQUIRED_ERROR("状態は必須です"),
                          })}
                        >
                          <option value="" selected>
                            選んでください
                          </option>
                          {stateMaster.map((item, index) => (
                            <option key={index} value={item.state_id}>
                              {item.state_name}
                            </option>
                          ))}
                        </select>
                        {errors?.state_id ? (
                          <span className="error">
                            {errors?.state_id?.message}
                          </span>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <label className="form-label d-block">タグ</label>

                        {tags.map((item, index) => (
                          <div
                            key={index}
                            className="form-check form-check-inline "
                          >
                            <input
                              className="form-check-input "
                              type="checkbox"
                              name="tag_ids"
                              value={item?.tag_id}
                              id={`tagCheckbox-${index}`}
                              onClick={() => checkBoxfunction(item?.tag_id)}
                              checked={
                                selectedTags.includes(item?.tag_id)
                                  ? true
                                  : false
                              }
                              {...register(`tag_ids`)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`tagCheckbox-${index}`}
                            >
                              {item.tag_name}
                            </label>
                          </div>
                        ))}
                      </div>
                      {isAdmin === "true" && ( <div className="mb-3  property-input">
                        <label className="form-label">物件のステータス</label>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          {...register("property_status")}
                        >
                          <option value="" selected>
                            選んでください
                          </option>
                          {stuatusMaster.map((item, index) => (
                            <option value={item.status_id}>
                              {item.status_name}
                            </option>
                          ))}
                        </select>
                      </div>)}
                      <div className="mb-3 remarks-input">
                        <label htmlFor="remarks" className="form-label">
                          備考
                        </label>
                        <textarea
                          className="form-control"
                          id="remarks"
                          maxLength="500"
                          rows="3"
                          {...register("remarks")}
                        ></textarea>
                      </div>
                    </div>
                    <h4 className="fontWidth mb-3">所有者情報</h4>
                    <div className="ps-3">
                      <div className="mb-3 address">
                        <label className="form-label">氏名</label>
                        <input
                          type="text"
                          className="form-control "
                          maxLength="101"
                          {...register("owner_name", {
                            required: FIELD_REQUIRED_ERROR("氏名は必須です "),
                            maxLength: {
                              value: 100,
                              message: ADDRESS_FILED100,
                            },
                          })}
                        />
                        {errors?.owner_name ? (
                          <span className="error">
                            {errors?.owner_name?.message}
                          </span>
                        ) : null}
                      </div>
                      <div className="mb-3 address">
                        <label className="form-label">連絡先</label>
                        <input
                          type="text"
                          className="form-control"
                          maxLength="51"
                          {...register("owner_contact", {
                            required: FIELD_REQUIRED_ERROR("連絡先は必須です"),
                            maxLength: {
                              value: 50,
                              message: ADDRESS_FILED50,
                            },
                          })}
                        />
                        {errors?.owner_contact ? (
                          <span className="error">
                            {errors?.owner_contact?.message}
                          </span>
                        ) : null}
                      </div>
                      <div className="mb-3 address">
                        <label className="form-label">住所</label>
                        <input
                          type="text"
                          className="form-control"
                          maxLength="256"
                          {...register("owner_address", {
                            required: FIELD_REQUIRED_ERROR("住所は必須です"),
                            maxLength: {
                              value: 255,
                              message: ADDRESS_FILED255,
                            },
                          })}
                        />
                        {errors?.owner_address ? (
                          <span className="error">
                            {errors?.owner_address?.message}
                          </span>
                        ) : null}
                      </div>
                      <div className="mb-3 remarks-input">
                        <label htmlFor="remarks" className="form-label">
                          備考
                        </label>
                        <textarea
                          className="form-control"
                          id="remarks"
                          maxLength="500"
                          rows="3"
                          {...register("owner_remarks")}
                        ></textarea>
                      </div>
                    </div>
                    <div className="img-slider-box">
                      <h4 className="fontWidth mb-3">物件画像</h4>
                      <div className="d-xl-flex mb-3 imgupload-box align-items-center">
                        <div>
                          <div className="imageUploadContainer2">
                            <div
                              className={`parentMultiImage ${
                                selectedImages.length == 4
                                  ? "calssName1"
                                  : selectedImages.length == 5
                                    ? "calssName2"
                                    : ""
                                }`}
                            >
                              <Slider
                                {...settings}
                                className="MultiImageSlider"
                              >
                                {selectedImages?.map((item, index) => (
                                  <div className="sliderUploadedImageContainer ">
                                    <img
                                      key={index}
                                      src={item.property_id ? `${IMG_URL}${item?.filePath}` : item?.filePath}
                                    />
                                    <button
                                      className="buttonCross"
                                      type="button"
                                      onClick={() => handleRemoveImage(index)}
                                    >
                                      <span className="crossIcon">
                                        <RxCross2 />{" "}
                                      </span>
                                    </button>
                                  </div>
                                ))}
                              </Slider>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="upload-btn-wrapper ms-3">
                            <button type="button" className="btn">
                              {selectedImages.length >= 5 ? (
                                <img
                                  src="/assets/images/gallery-icon.png"
                                  className="img-fluid opacity-25 "
                                ></img>
                              ) : (
                                <img
                                  src="/assets/images/gallery-icon.png"
                                  className="img-fluid "
                                ></img>
                              )}
                            </button>
                            <br />
                            <input
                              type="file"
                              name="property_images"
                              accept="image/png, image/jpg, image/jpeg"
                              onChange={handleFileChange}
                              disabled={
                                selectedImages.length >= 5 ? true : false
                              }
                              // {...register('property_images', {
                              //   required: 'Please select at least one image.',
                              // })}
                              multiple
                            />


                          </div>
                        </div>
                      </div>
                    </div>

                  </section>
                </form>
              </main>
            ) : (
              <main>
                <p> ドラッグしてピンの位置を調整します。</p>
                <GoogleMapComponent accessName={"newMap"} />
                <div className="d-flex mt-3">
                  <p className="me-2 mb-0">
                    経度:<span>{data.lat}</span>
                  </p>
                  <p className="mb-0">
                    緯度:<span>{data.lng}</span>
                  </p>
                </div>
                {!(data.lat && data.lng) && locationerror  && <span className="error">場所は必須です</span>}
              </main>
            )}
          </Modal.Body>
          <Modal.Footer className="modal-footer pt-0 pb-5">
            <button
              type="button"
              className="btn btn-outline cancel-btn px-5"
              onClick={() => handlClickCancel()}
            >
              キャンセル
            </button>
            {next ? (
              <button
                type="button"
                className="btn btn-primary Proceed-btn px-4"
                onClick={handleSubmit(onSubmit)}
              >
                次へ進む
              </button>
            ) : (
              <div className="d-flex">
                <button
                  type="button"
                  className="btn btn-outline cancel-btn px-5 me-2"
                  onClick={() => SetNext(true)}
                >
                  戻る
                </button>
                {props.propertyId && <button
                  type="button"
                  className="btn btn-outline cancel-btn px-5 me-2"
                  onClick={() => {
                    allSubmitDataSkip();
                    reset();
                  }}
                >
                {skipbuttonLode ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "ピン位置を更新せず保存する"
                    )}
                </button>}
                <button
                  type="button"
                  className="btn btn-primary Proceed-btn px-5"
                  onClick={() => {
                    allSubmitData();
                    reset();
                  }}
                >
                  {buttonloder ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "ピン位置を更新して保存する"
                  )}
                </button>
              </div>
            )}
          </Modal.Footer>
        </Modal>
      )}

      {deleteModalShow && (
        <Modal
          show={deleteModalShow}
          onHide={() => setDeleteModalShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title
              id="contained-modal-title-vcenter"
              className="modal-header p-3"
            >
              物件の削除確認
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="delete-modal">
            <p className="p-4 text-center">
              削除すると、元には戻せません。
              <br />
              完全に削除しますか?
            </p>
          </Modal.Body>
          <Modal.Footer className="delete-footer pb-5">
            <button
              onClick={() => {
                setDeleteModalShow(false);
              }}
              className="btn btn-outline cancel-btn px-5 me-5"
            >
              キャンセル
            </button>
            <button
              onClick={() => deleteData()}
              className="btn btn-danger px-5"
            >
              {buttonloder ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "削除する"
              )}
            </button>
          </Modal.Footer>
        </Modal>
      )}

      {cancelModalShow && (
        <Modal
          show={cancelModalShow}
          onHide={() => {
            reset();
            setCancelModalShow(false);
            SetNext(true);
            fetchData();
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title
              id="contained-modal-title-vcenter"
              className="modal-header p-3"
            >
              物件追加・編集のキャンセル確認
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="delete-modal">
            <p className="p-4 text-center">
              未保存の内容が破棄されます。 <br />
              本当にキャンセルしますか?
            </p>
          </Modal.Body>
          <Modal.Footer className="delete-footer pb-5">
            <button
              onClick={() => setCancelModalShow(false)}
              className="btn btn-outline cancel-btn px-5 me-5"
            >
              いいえ
            </button>
            <button
              onClick={() => {
                props.onHide();
                setCancelModalShow(false);
                clearSteat()
                reset();
                SetNext(true);
                fetchData();
              }}
              className="btn btn-danger px-5"
            >
              はい
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default ModalAdd;
