
import React, { useEffect, useRef,  } from 'react';
import './homestyle.css'
import { fromLatLng } from 'react-geocode';
import { useDispatch, useSelector } from 'react-redux';
import {setLocation } from '../../redux/locationSlice';

function GoogleMapComponent(props) {
  const markerRef = useRef(null);
  const mapRef2 = useRef(null);
  const dispatch = useDispatch()
  const location = useSelector((state) => state);
  const editLocation = useSelector((state) => state?.location?.editLocationData);
  useEffect(() => {
    async function initMap() {
      const image2 ="/assets/images/Checkpoint1.png"
      if(props.accessName === 'newMap' && mapRef2.current){
        const { Map } = await window.google.maps.importLibrary('maps');
        const data = Object.keys(editLocation).find((el)=> el== "property_id") 
        const latlngs = Object.keys(location.location.lnglat).find((el)=> el=== "lat") ?  location.location.lnglat :{lat: 36.2048, lng:138.2529} ;
        const editlnglat = editLocation?.latitude === location.location.lnglat.lat && editLocation?.longitude === location.location.lnglat.lng ?{lat: Number(editLocation?.latitude), lng: Number(editLocation?.longitude)}:location.location.lnglat
        const map2 = new Map(mapRef2.current, {
          zoom: 8,
          center:data ? editlnglat : latlngs,
          mapId: 'e7f00e4f77c30c63'
        });
        
        const icon2 = {
          url:image2,
          scaledSize: new window.google.maps.Size(25, 25), 
        }

        const marker = new window.google.maps.Marker({
          map: map2,
          draggable: true,
          position:data ? editlnglat : latlngs ,
          icon: icon2
        });
        marker.addListener("dragend", (event) => {
          const newLat = event.latLng.lat();
          const newLng = event.latLng.lng();
          
          fromLatLng(newLat, newLng).then(
            response => {
                dispatch(setLocation(response.results[0]))
            },
            error => {
              console.error(error);
            }
          );
        })
        markerRef.current = marker;
      }
    }
   
   
    if (props.accessName) {
      initMap();
    }
  }, [props.accessName === "homePage",]);

  return (
    <>

        {props.accessName === 'newMap' && <div id="map2" ref={mapRef2} style={{ height: '50vh', width: '100%' }}>
        </div>}
    </>
  );
}

export default GoogleMapComponent;

