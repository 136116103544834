import { useForm } from "react-hook-form";
import "./loginstyle.css";
import {
  FIELD_REQUIRED_ERROR,
  INVALID_FIELD_ERROR,
} from "../../Common/Validation/errorMessage";
import { EMAIL_REGEX } from "../../Common/Validation/regex";
import {
  BAD_REQUEST,
  CODE_SUCCESS,
  INTERNAL_SERVER,
  baseURL,
} from "../../Common/api/common";
import axios from "axios";
import { BiSolidLockAlt } from "react-icons/bi";
import { HiOutlineUser } from "react-icons/hi2";
import { toast } from "react-toastify";
function Login() {
  const {
    register,
    handleSubmit,
    formState: {  isValid },
    watch,
  } = useForm();

  const onSubmit = async (values) => {
    axios
      .post(`${baseURL}/auth/user-login`, values)
      .then(function (response) {
        if (response.status === CODE_SUCCESS) {
          localStorage.setItem("isAdmin", response.data.data.isAdmin);
          localStorage.setItem("token", response.data.data.token);
          window.location.href = "/";
        }
      })
      .catch(function (error) {
    
        if (error.response.status === INTERNAL_SERVER) {
          toast.error(`${error.response.data.error}`);
        }
        if (error.response.status === BAD_REQUEST) {
          toast.error(`${error.response.data.error}`);
        }
        if(error.message === "Network Error")
        console.log(error);
      })
      .finally(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-6">
          <div className="login-details text-center p-5">
            <div>
              <h1>
              新潟県三条市<br /> 空き家マップ
              </h1>
              <img
                src="/assets/images/login-img.png"
                alt="img"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="login-form-box p-5">
            <div className="log-form-box p-5">
              <div className="login-form w-100">
                <h2>ログイン</h2>
                <p>権限を持つユーザーのみがログイン可能です。</p>
                <form
                  className="log-form mt-3"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="mb-4 position-relative">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                    ユーザーID
                    </label>
                    <input
                      type="email"
                      className={`${watch("email")? "form-control ps-5 boxshowd" :"form-control ps-5"}`}
                      id="exampleInputEmail1"
                     
                      {...register("email", {
                        required: FIELD_REQUIRED_ERROR("Email"),
                        pattern: {
                          value: EMAIL_REGEX,
                          message: INVALID_FIELD_ERROR("email address"),
                        },
                      })}
                    />
                    <HiOutlineUser style={{ color: watch("email") ? " #051D34" : "#999FAE" }}  className="img-fluid login-icon" />
                    {/* {errors?.email ? (
                     <span className="error">{errors?.email?.message}</span>
                   ) : null} */}
                  </div>
                  <div className="mb-4 position-relative">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="form-label"
                    >
                      パスワード
                    </label>
                    <input
                      type="password"
                      className={`${watch("password")? "form-control ps-5 boxshowd" :"form-control ps-5"}`}
                      id="exampleInputPassword1"
                      {...register("password", {
                        required: FIELD_REQUIRED_ERROR("Password"),
                      })}
                    />
                     <BiSolidLockAlt    style={{ color: watch("password") ? " #051D34" : "#999FAE" }} className="img-fluid login-icon" />
                   
                    {/* {errors?.password ? (
                  <span className="error">{errors?.password?.message}</span>
                ) : (
                null
                )} */}
                  </div>
                  <button
                    type="submit"
                    className={`btn btn-primary w-100 mt-3 ${!isValid?'btn-disabled':'btn-primary '}`} 
                    disabled={!isValid}
                  >                    
                   ログイン
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
