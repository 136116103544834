import { createSlice } from '@reduxjs/toolkit'

const initialState = {
 selectedMarker:{},
 isActtion:false,
}

export const markerSlice = createSlice({
  name: 'marker',
  initialState,
  reducers: {
    setSelectedMarker: (state, action) => {
       const data = action.payload.selectedMarker
       const isactive = action.payload.isActtion
      state.selectedMarker = { ...state.selectedMarker, ...data };
      state.isActtion = isactive;
    },
  },
})

// Action creators are generated for each case reducer function
export const {setSelectedMarker } = markerSlice.actions

export default markerSlice.reducer