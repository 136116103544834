import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  locationData: {},
  editLocationData: {},
  lnglat:{},
}

export const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setLocation: (state, action) => {
      state.locationData = { ...state.locationData, ...action.payload };
    },
    setLaglat: (state, action) => {
      state.lnglat = { ...state.lnglat, ...action.payload };
    },
    removeLocation: (state, action) => {
      state.locationData = { initialState, ...action.payload };
    },
    removeLaglat: (state, action) => {
      state.lnglat = { initialState, ...action.payload };
    },
    setEditLocationData: (state, action) => {
      state.editLocationData = { ...state.editLocationData, ...action.payload }
    },
    removeEditLocationData: (state, action) => {
      state.editLocationData = { initialState, ...action.payload }
    }
  },
})

// Action creators are generated for each case reducer function
export const { removeLocation, setLocation,setEditLocationData,setLaglat ,removeEditLocationData,removeLaglat} = locationSlice.actions

export default locationSlice.reducer