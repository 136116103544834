import React, { createRef, useEffect, useState } from "react";
import { Card } from "antd";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./card.css";
import Carousel from "react-bootstrap/Carousel";
import ModalAdd from "../Modal/modal";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedMarker } from "../../redux/markerSlice";
import { IMG_URL } from "../../Common/api/common";

const Cards = (props) => {
  moment.updateLocale("ja", {
    months: [
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月",
    ],
    weekdays: [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日",
    ],
    weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"],
  });
  const dispatch = useDispatch();
  var propertyData = props.item;
  const isactive = useSelector((state) => state?.marker?.isActtion);
  const id = useSelector((state) => state?.marker?.selectedMarker.property_id);
  const [cardCount, setCardCount] = useState(0);

  useEffect(() => {
    var propertyData = props.item;
    const indexValue = propertyData?.findIndex(
      (item) => item.property_id == id
    );
    setCardCount(indexValue);
    cardRefs[indexValue]?.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }, [id, isactive, propertyData]);

  const idProperty = propertyData[cardCount];
  const cardRefs = propertyData.map(() => createRef());
  useEffect(() => {
    dispatch(
      setSelectedMarker({ selectedMarker: idProperty, isActtion: false })
    );
  }, []);
  const ids = propertyData[0]?.property_id;
  const [modalShow, setModalShow] = React.useState(false);
  const [propertyId, setPropertyId] = useState(null);
  const showModal = (propertyId) => {
    setPropertyId(propertyId);
    setModalShow(true);
  };

  const handlClick = (id) => {
    dispatch(setSelectedMarker({ selectedMarker: id, isActtion: false }));
  };
  const handleClick1 = (index1) => {
    propertyData.map((i, index) => {
      if (index == index1) {
        return dispatch(
          setSelectedMarker({ selectedMarker: i, isActtion: false })
        );
      }
    });
    if (index1 >= 0 && propertyData.length - 1 >= index1) {
      setCardCount(index1);
    } else if (propertyData.length - 1 <= cardCount) {
      setCardCount(cardCount);
    } else {
      setCardCount(0);
    }
    cardRefs[index1]?.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };
  return (
    <>
      <div className="bottom-btn p-3 text-end">
        <button
          className="btn btn-primary ps-5 pe-5 me-3"
          disabled={cardCount == -1}
          onClick={() => {
            handleClick1(cardCount - 1);
          }}
        >
          <img
            src="/assets/images/up-icon.png"
            className="img-fluid select-button"
          ></img>
        </button>
        <button
          className="btn btn-primary  ps-5 pe-5"
          onClick={() => {
            handleClick1(cardCount + 1);
          }}
        >
          <img
            src="/assets/images/up-icon.png"
            className="img-fluid select-button down-icon"
          ></img>
        </button>
      </div>

      {propertyData?.map((item, index) => {
        const isCardClicked1 = isactive
          ? id == item.property_id
          : idProperty?.property_id === item.property_id;
        return (
          <div>
            <Card
              className={isCardClicked1 ? "clicked-card" : ""}
              style={{
                display: "flex",
                width: "100%",
                cursor: "pointer",
              }}
            >
              <div className="row ps-3 pt-3 w-100">
                <div className="col-lg-5">
                  <div className="position-relative w-100">
                    <div
                      className="yellow-label2 bottom-0"
                      style={{backgroundColor:item?.association_detail?.residents_association_name?"#FFDE5C":""}}>
                      {item?.association_detail?.residents_association_name}
                    </div>
                    {/* 物件画像 */}
                    <Carousel data-bs-interval="false" interval={null}>
                      {item.property_images.length > 0 ? (
                        item.property_images?.map((item, index) => {
                          return (
                            <Carousel.Item key={index}>
                              <img
                                src={`${IMG_URL}${item.filePath}`}
                                className="d-block"
                                loading="lazy"
                              />
                            </Carousel.Item>
                          );
                        })
                      ) : (
                        <Carousel.Item>
                          <img
                            src="/assets/images/noimage.png"
                            className="d-block"
                          />
                        </Carousel.Item>
                      )}
                    </Carousel>
                  </div>
                </div>
                <div className="col-lg-7 p-0 ps-2"    
                 key={index}
              ref={cardRefs[index]}
              onClick={() => {
                handlClick(item);
                handleClick1(index);
              }}>
                  <div className="card-details position-relative">
                    <button
                      className="btn edit-icon p-0"
                      onClick={() => showModal(item.property_id)}
                    >
                      <img
                        src="/assets/images/edit.png"
                        className="img-fluid"
                      ></img>
                    </button>
                    <div className="property-info">
                      <table className="table table-sm table-borderless">
                        <tbody>
                        <tr className="title">
                            <td colSpan="2">
                              物件情報
                            </td>
                          </tr>
                          <tr className="value">
                            <td className="col-3">エリア</td>
                            <td className="col-9">
                              {item?.association_detail?.area_detail?.area_name}
                            </td>
                          </tr>
                          <tr className="value">
                            <td>住所</td>
                            <td>{item.address}</td>
                          </tr>
                          <tr className="value">
                            <td>状態</td>
                            <td>{item.property_state.state_name}</td>
                          </tr>
                          <tr className="value">
                            <td>築年</td>
                            <td>
                              {item.building_year}
                            </td>
                          </tr>
                          <tr className="value">
                            <td>タグ</td>
                            <td>
                              {item.property_tags.map((item, index) => (
                                <span className="me-2" key={index}>
                                  #{item.tag_detail.tag_name}
                                </span>
                              ))}
                            </td>
                          </tr>
                          <tr className="value">
                            <td>ステータス</td>
                            <td>
                              {item?.propertyStatus?.status_name} 
                            </td>
                          </tr>
                          <tr className="value">
                            <td>備考</td>
                            <td>{item.remarks}</td>
                          </tr>
                          <tr className="title">
                            <td colSpan="2">所有者情報</td>
                          </tr>
                          <tr className="value">
                            <td>氏名</td>
                            <td>{item.owner_name}</td>
                          </tr>
                          <tr className="value">
                            <td>連絡先</td>
                            <td>{item.owner_contact}</td>
                          </tr>
                          <tr className="value">
                            <td>住所</td>
                            <td>{item.owner_address}</td>
                          </tr>
                          <tr className="value">
                            <td>備考</td>
                            <td>{item.owner_remarks}</td>
                          </tr>
                          <tr className="title">
                            <td colSpan="2">更新情報</td>
                          </tr>
                          <tr className="value">
                            <td>登録日時</td>
                            <td>
                              {moment(item.createdAt).format(
                                "YYYY/MM/DD HH:mm"
                              )}
                            </td>
                          </tr>
                          <tr className="value">
                            <td>最終更新日時</td>
                            <td>
                              {moment(item.updatedAt).format(
                                "YYYY/MM/DD HH:mm"
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        );
      })}
      <ModalAdd
        show={modalShow}
        onHide={() => setModalShow(false)}
        propertyId={propertyId}
      />
    </>
  );
};

export default Cards;
